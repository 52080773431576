import React from 'react';
import styled from '@emotion/styled';
import { SYSTEM_FONTS } from '../../styles/typography';

export interface HeadingProps {
  as?: string;
  level?: number;
  bold?: boolean;
  isTopSection?: boolean;
}

const getFontSize = (level: number) => {
  switch (level) {
    case 1:
      return 48;
    case 2:
      return 40;
    case 3:
      return 36;
    case 4:
      return 28;
    case 5:
      return 24;
    case 6:
      return 20;
    default:
      return 16;
  }
};

const topSection = (level: number, isTopSection: boolean) => {
  if (isTopSection) {
    return `
    max-width: 200px;
  font-size: 20px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 960px) {
    font-size: ${level ? getFontSize(level) : '30px'}px;
    margin-top: 20px;
    max-width: 400px;
  }

  @media (min-width: 1200px) {
    font-size: ${level ? getFontSize(level) : '40px'}px;
    margin-top: 20px;
    max-width: 400px;
  }`;
  }
};

const StyledHeading = styled.h2<HeadingProps>`
  font-family: ${['Blacker Pro Display'].concat(SYSTEM_FONTS).join(', ')};
  font-size: ${({ level }) => (level ? getFontSize(level) : '20px')}px;
  font-weight: ${({ bold }) => (bold ? 600 : 400)};
  color: #150b2c;

  ${({ level, isTopSection }) =>
    isTopSection && topSection(level, isTopSection)};
`;

const Heading: React.FC<HeadingProps> = ({ bold, level = 2, ...rest }) => (
  <StyledHeading {...rest} as={`h${level}`} level={level} bold={bold} />
);

export default Heading;
